import { useState } from "react";
import ApplicationCourses from "../Components/ApplicationCourses";
import Axios from "axios";

const Apply = () => {


    const API_URL = 'https://backend-api.techsyiq.com/application/create_application';

    const [data, setData] = useState({
        name: '',
        phone_no: '',
        email: '',
        module: ''
    })

    function submit(e){
        e.preventDefault();
        Axios.post(API_URL,{
            name: data.name,
            email: data.email,
            phone_no: data.phone_no,
            module: data.module
        }).then(res=>{
            document.getElementById('thankYou').classList.remove('d-none');
            document.getElementById('applyForm').reset()
        })
    }

    function dismissThankYou(){
        document.getElementById('thankYou').classList.add('d-none');
    }

    function handle(e){
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        console.log(newData)
    }

  return (
    <>
    <section id="thankYou" className="d-none form-submit-success" onClick={dismissThankYou}>
        <div className="thank-you-inner">
            <h3>Application Successful!</h3>
            <p>Your application was submitted successfully! We will get in touch!</p>
            <button onClick={dismissThankYou} className="btn btn-main btn-sm">OK</button>
        </div>
    </section>
      <section class="py-4 py-lg-5 why-us-section">
        <div class="container">
            <h1 className="text-center mb-4">Apply Now</h1>
            <div className="row">
                <div className="mt-3">
                    <form className="site-card" id="applyForm" onSubmit={(e)=> submit(e)}>
                        <div className="row">
                            <div className="col-md-6 my-2">
                                <label for="name" className="small">Your Name</label>
                                <input name="name" onChange={(e)=>handle(e)} type="text" className="form-control" id="name" />
                            </div>
                            <div className="col-md-6 my-2">
                                <label for="phone_no" className="small">Phone Number</label>
                                <input type="tel" onChange={(e)=>handle(e)} name="phone_no"  className="form-control" id="phone_no" />
                            </div>
                            <div className="col-md-6 my-2">
                                <label for="email" className="small">Email Address</label>
                                <input type="email" name="email" onChange={(e)=>handle(e)} className="form-control" id="email" />
                            </div>
                            <div className="col-md-6 my-2">
                                <label for="module" className="small">Module</label>
                                <select className="form-select" onChange={(e)=>handle(e)} id="module" name="module">
                                    <option selected disabled>Select Module</option>
                                    <ApplicationCourses />
                                </select>
                            </div>
                            {/* <div className="col-md-4 my-2">
                                <label for="module" className="small">Starting Date</label>
                                <select className="form-select">
                                <option>2nd Feb</option>
                                </select>
                            </div> */}
                            <div className="col-md-12 mt-2">
                                <button type="submit" className="btn btn-main">Submit Application</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

    </>
  );
}

export default Apply;
