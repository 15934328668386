import { useEffect, useState } from 'react';

import Newsletters from "../Components/Newsletters";
import Testimonials from "../Components/Testimonials";

import HomeCourseList from '../Components/HomeCourseList';
import Stats from '../Components/Stats';
const API_URL = 'https://backend-api.techsyiq.com/course/fetch_course';

const Home = () => {
    const [courseList, setCourses] = useState([])
  
    const courses = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setCourses(data)
  }


  useEffect(() => {
    courses();
  }, []);



  return (
    <>

      <section class="hero-section">
        <div class="py-4 py-lg-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1>Software Development <br/>
                            <span class="text-blue">Bootcamp</span>
                            <span class="text-pink">.</span>
                            <span class="text-orange">.</span>
                            <span class="text-blue">.</span>
                        </h1>
                        <p>From African's leading technology and innovation hub, Kenya, we are planting the seeds for the next breed of techies, and with it a robust, thriving community of learning, sharing, having fun, and nurturing software developers. We welcome everyone!</p>

                        <p class="hero-styled-p">PSS, FULLY REMOTE</p>

                        <a href="/apply-now" className="btn btn-main">Apply</a>
                        <a href="/book-call" className="btn btn-login">Book a Call</a>
                    </div>
                    <div class="col-lg-6">
                        <span class="vector-1"></span>
                        <span class="vector-2"></span>
                        <span class="vector-3"></span>
                    </div>
                </div>
            </div>
        </div>
      </section>

      <section class="pb-4 pb-lg-5 why-us-section">
        <div class="container">
            <div class="home-why-us-card site-card">
                <h2 class="fancy-heading">Why Us?</h2>
                <div class="row mt-4 gx-lg-5">
                    <div class="col-md-6 col-lg-4 my-2">
                        <div class="why-us-card">
                            <img src="./img/FlippedClassrooms.svg" alt="Flipped classrooms" />
                            <h3>Flipped classrooms</h3>
                            <p>Study theory at home and focus on practice in live classes, where you’ll be guided by one of our expert tutors.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 my-2">
                        <div class="why-us-card">
                            <img src="./img/LiveClasses.svg" alt="Live classes" />
                            <h3>Live classes</h3>
                            <p>Join our Zoom classes led by industry experts — up to 15 students per class, during stand-ups.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 my-2">
                        <div class="why-us-card">
                            <img src="./img/Support.svg" alt="Support" />
                            <h3>Support</h3>
                            <p>Get the help you need to break into your new career. We provide our students with meaningful support and career guidance.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 my-2">
                        <div class="why-us-card">
                            <img src="./img/SoftSkills.svg" alt="Soft Skills" />
                            <h3>Soft Skills</h3>
                            <p>Go beyond the technical stuff. Our approach emulates a real tech working environment, where our students work in groups, guided by a team lead.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 my-2">
                        <div class="why-us-card">
                            <img src="./img/Employability.svg" alt="Employability" />
                            <h3>Employability</h3>
                            <p>Landing a job in tech can be tough — but we’ll provide you with the tools and knowledge necessary to nail your first interview.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 my-2">
                        <div class="why-us-card">
                            <img src="./img/Community.svg" alt="Community" />
                            <h3>Community</h3>
                            <p>Get access to the Techsyiq Community that comprises our alumni, a vibrant, innovative development company, and a growing network of partners.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container home-courses mt-5">
            <h2 class="fancy-heading">Our Courses</h2>
            {
                courseList.length > 0
                ? (
                <div className="row">
                    {courseList.map((course) => 
                    <HomeCourseList course={course} />
                    )}
                </div>
                )
                :
                <div>
                </div>
            }
        </div>
    </section>

    <section class="py-4 py-lg-5 cta-section">
        <div class="container">
            <div class="row align-items-center min-height">
                <div class="col-md-6">
                    <span class="vector-1"></span>
                    <span class="vector-2"></span>
                    <span class="vector-3"></span>
                </div>
                <div class="col-md-6">
                    <h2>Not sure where you fit?</h2>
                    <p>Talk to our technical advisors who will help you select the right course, prepare for admission, or just advise you on your next career goal.</p>
                    <a href="/book-call" className="btn btn-main">Book a Call</a>
                </div>
            </div>
        </div>
      </section>

      <section class="testimonials py-4 py-lg-5 mt-5">
        <Testimonials />
      </section>

      <Stats />
      <Newsletters />

    </>
  );
}

export default Home;
