import Axios from "axios";
import { useState } from "react";

const Newsletters = () => {

    const API_URL = 'https://backend-api.techsyiq.com/marketing/subscribe';

    const [data, setData] = useState({
        email: '',
    })

    function submit(e){
        e.preventDefault();
        Axios.post(API_URL,{
            email: data.email,
        }).then(res=>{
            document.getElementById('thankYouNewsletter').classList.remove('d-none');
            document.getElementById('newsletterForm').reset()
        })
    }

    function dismissThankYou(){
        document.getElementById('thankYouNewsletter').classList.add('d-none');
    }

    function handle(e){
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        console.log(newData)
    }

  return (
    <>
        <section id="thankYouNewsletter" className="d-none form-submit-success" onClick={dismissThankYou}>
            <div className="thank-you-inner">
                <h3>Thank you for subscribing!</h3>
                <p>Thank you for subscribing to our newsletter! We will keep you updated!</p>
                <button onClick={dismissThankYou} className="btn btn-main btn-sm">OK</button>
            </div>
        </section>
        <section className="py-4 py-lg-5 newsletter">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-7 my-3">
                        <h2 className="text-blue">Subscribe to our newsletter.</h2>
                        <p>Receive news, updates and new blog posts right on your mail. No spam, no junk, just what you need to stay in the know about what going on at Techsyiq.</p>
                        <form onSubmit={(e)=> submit(e)} id="newsletterForm">
                            <input type="email" name="email" onChange={(e)=>handle(e)} id="email" className="form-control" placeholder="Your Email" />
                            {/* <div className="form-check my-3">
                                <input type="checkbox" className="form-check-input" name="" id="acceptTerms" />
                                <label for="acceptTerms">By submitting this form, I accept the <a href="/privacy-policy" className="text-blue">Privacy Policy</a> and the <a href="/terms-and-conditions" className="text-blue">Terms of Use</a></label>
                            </div> */}
                            <label for="acceptTerms" className="my-3 small d-block fw-400">By submitting this form, I accept the <a href="/privacy-policy" className="text-blue">Privacy Policy</a> and the <a href="/terms-and-conditions" className="text-blue">Terms of Use</a></label>
                            <button type="submit" className="btn btn-main">Subscribe</button>
                        </form>
                    </div>
                    <div className="col-md-6 col-lg-5 my-3 ps-md-5">
                        <img src="./img/img.svg" width="100%" alt="" />
                        <span className="vector-1"></span>
                        <span className="vector-2"></span>
                        <span className="vector-3"></span>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Newsletters;
