import { useEffect, useState } from 'react';

import Newsletters from "../Components/Newsletters";

import BlogList from '../Components/BlogList';
import CallToAction from '../Components/CallToAction';
import BlogTabs from '../Components/BlogTabs';
import BlogTabsContent from '../Components/BlogTabContent';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';


const Blog = () => {
    const [blogs, setBlogs] = useState([])
  
  const blogArticles = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setBlogs(data)
  }


  useEffect(() => {
    blogArticles();
  }, []);



  return (
    <>

        <section className="py-4 py-lg-5">
            <div className="container">
                <h1 className="text-blue">Techsyiq Blog</h1>
                <p>Read about our students, their success stories, and experience at Techsyiq.</p>

                <ul className="nav nav-pills mt-4 mb-5 custom-tab-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All Articles</button>
                    </li>
                    <BlogTabs />
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-all" role="tabpanel">
                        {
                            blogs.length > 0
                            ? (
                            <div>
                                {blogs.map((blog) => 
                                    <BlogList blog={blog} />
                                )}
                            </div>
                            )
                            :
                            <div>
                            </div>
                        }
                    </div>
                    <BlogTabsContent />
                </div>
            </div>
        </section>

      <Newsletters />

      <section className="py-4 py-lg-5">
        <div className="container py-4">
            <div className="accordion" id="accordionFAQs">
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsOne" aria-expanded="true" aria-controls="collapseFAQsOne">
                        <span>
                            <span className="site-accordion-title">Can someone with zero experience in programming enroll your bootcamp ?</span>
                        </span>
                    </button>
                    <div id="collapseFAQsOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFAQs">
                    <div className="accordion-body">
                    Techsyiq is designed to cater to individuals with zero or minimal programming experience, making it an ideal choice for those looking to embark on a coding journey. 
                    The bootcamp offers beginner-friendly courses that commence with the essential principles of programming, gradually advancing to more complex topics.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsTwo" aria-expanded="true" aria-controls="collapseFAQsTwo">
                        <span>
                            <span className="site-accordion-title">Do you invite mentors in software development to motivate your cohorts?</span>
                        </span>
                    </button>
                    <div id="collapseFAQsTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                    <div className="accordion-body">
                        <p>
                        Certainly, we wholeheartedly embrace the spirit of mentorship and career guidance at Techsyiq. We conduct sessions with industry specialists every last Friday of the month to actively engage with our Techies. 
                        This serves as a valuable networking opportunity, allowing our Techies to have one-on-one interactions with potential employers.</p>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsThree" aria-expanded="true" aria-controls="collapseFAQsThree">
                        <span>
                            <span className="site-accordion-title">Do you provide Laptops to your Techies ?</span>
                        </span>
                    </button>
                    <div id="collapseFAQsThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                    <div className="accordion-body">
                        <p>We are hopeful to provide laptops to our Techies in the future, particularly to those who truly need our support in that area. 
                        Currently, we encourage each Techie to have their own laptop for the bootcamp.</p>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsFour" aria-expanded="true" aria-controls="collapseFAQsFour">
                        <span>
                            <span className="site-accordion-title">Why don’t you teach Angular?</span>
                        </span>
                    </button>
                    <div id="collapseFAQsFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                    <div className="accordion-body">
                        <p>
                        At Techsyiq, we decided to teach React over Angular in our coding bootcamp for several compelling reasons. React's extensive industry adoption and popularity, coupled with its ease of learning for beginners through a 
                        component-based architecture, align with our commitment to providing a smooth onboarding experience for aspiring developers. </p>
                        <p>The flexibility inherent in React, allowing developers to structure projects according to their needs, adds versatility to our curriculum. 
                        We value React's virtual DOM, enhancing performance in dynamic web applications, as well as the significant advantages offered by its large and vibrant community, extensive ecosystem, and high demand in the job market.</p>
                        <p>This decision reflects our dedication to offering a curriculum that not only equips students with valuable skills but also aligns with current industry trends and preferences.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      <CallToAction />

    </>
  );
}

export default Blog;
