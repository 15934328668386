
const CourseTutors = () => {
    return (
        <div className="course-tutors-section-outer">
            <span className="v1"></span>
            <span className="v2"></span>
            <span className="v3"></span>
            <section className="py-4 py-lg-5 cta-section course-tutors-section">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <h2>Our Tutors</h2>
                            <p>All tutors pass multiple interviews to ensure aligning values, as well as impeccable soft and technical skills. The final step is a demo className where they teach a topic to other teachers. </p>
                            <p>Our team of methodologists supports and trains them continuously. This is the place where industry experts become great teachers.</p>
                        </div>
                    </div>
                </div>
            </section>  
        </div>
    );
  }
  
  export default CourseTutors;
  