
const LearningProcess = () => {
    return (
        <>
        <section class="py-4 py-lg-5 learning-process">
            <div class="container py-4">
                <h2>Learning process</h2>
                <div class="col-md-8">
                <p>We use the flipped classroom, which means you study theory on your own and practice it in class with the teacher’s guidance and classmates’ support.</p>
                </div>
                <div class="site-card bg-blue-1 p-lg-5 mt-5">
                    <div class="row">
                        <div class="col-md-4 my-2 pe-lg-5">
                            <img src="../img/learning-process.png" width="100%" alt="" />
                        </div>
                        <div class="col-md-8 my-2">
                            <h5>Theory</h5>
                            <p class="fw-bold text-theme">70 hours +</p>
                            <p>Before every live class, you will get access to an expertly crafted set of theoretical resources (videos, external links, and terminology). In class, your tutor will explain anything you may have found difficult during preparation.</p>

                            <h5 class="mt-4">Live Classes</h5>
                            <p class="fw-bold text-theme">126 hours +</p>
                            <p class="mb-0">In live classes, you will have homework discussions with your teacher, complete practical assignments, and work on group and individual projects.</p>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center mt-5 pt-4">
                    <div class="col-md-7 pe-lg-5">
                        <h2>Career Support</h2>
                        <p>We'll assist you with writing your first CV, make sure you nail your interviews, and help you navigate the job market. Our goal is to get you started in the tech industry, not just pass a course.</p>
                    </div>
                    <div class="col-md-5">
                        <img src="../img/learning-path.svg" width="100%" alt="" />
                    </div>
                </div>
            </div>
        </section>
    </>
    );
  }
  
  export default LearningProcess;
  