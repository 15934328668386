import { useEffect, useState } from "react";

const ApplicationCourses = () => {

    const API_URL = 'https://backend-api.techsyiq.com/course/fetch_course';

    const [courseList, setCourses] = useState([])
  
    const courses = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setCourses(data)
  }

  useEffect(() => {
    courses();
  }, []);

  return (
    <>
        {courseList.map((course) => 
            <option value={course.name}>{course.name}</option>
        )}
    </>
  );
}

export default ApplicationCourses;
