import { useEffect, useState } from 'react';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Testimonials = () => {
    const API_URL = 'https://backend-api.techsyiq.com/testimonial/fetch';

    new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        spaceBetween: 30,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // When window width is <= 999px (for mobile)
            999: {
                slidesPerView: 1,
            },
            // When window width is > 999px (for desktop)
            1000: {
                slidesPerView: 3,
            },
        },
    });
    

    const [testimonialList, setTestimonial] = useState([])
    
        const testimonials = async () => {
        const response = await fetch(API_URL);
        const data = await response.json();
        
        console.log(data)
        setTestimonial(data)
    }


    useEffect(() => {
        testimonials();
    }, []);

  return (
    <>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-4">
                <h2 className="text-white">Testimonials</h2>
                <p className="text-light">What do some of our alumni say about their experience at our bootcamp? Many highlight the practical approach to learning, 
                finding it instrumental in their skill development. Explore firsthand the transformative stories of alumni, detailing skill acquisition, 
                confidence building, and success in the Tech World. </p>
            </div>
            <div className="col-md-8">
                <div className="swiper">
                    <div className="swiper-wrapper">
                        {testimonialList.map((testimonial) => 
                            <div className="swiper-slide my-1">
                                <div className="testimonial-card">
                                    <img src={"https://res.cloudinary.com/dyfyhudy0/" + testimonial.image } className="prof-img" alt="" />
                                    <div className="testimonial-content">
                                        <a href={ testimonial.profile_link } target="_blank" className="linkedin-icon">
                                            <i className="bi bi-linkedin"></i>
                                        </a>
                                        <p className="mb-2">{ testimonial.description }</p>
                                        <img src={"https://res.cloudinary.com/dyfyhudy0/" + testimonial.logo } className="company-img" alt="" />
                                        <h5 className="mb-1">{ testimonial.name }</h5>
                                        <p className="small mb-0 text-muted lc-1">{ testimonial.title }</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </div>
    </div>
    <span className="testimonial-vectors">
        <span className="v1"></span>
        <span className="v2"></span>
        <span className="v3"></span>
    </span>
    </>
  );
}

export default Testimonials;
