const PrivacyPolicy = () => {
  return (
    <>
      <section class="py-4 py-lg-5">
        <div class="container">
            <h1>Privacy Policy</h1>
         
      <section>
          <h3>1. Introduction</h3>
          <p>Welcome to TECHSYIQ. We are committed to protecting your privacy and complying
              with applicable data protection laws, including the Data Protection Act of Kenya.</p>
      </section>

      <section>
          <h3>2. Information We Collect</h3>
          <p>We may collect personal information, such as your name and email address, when you register on our website or
              subscribe to our newsletter.</p>
      </section>

      <section>
          <h3>3. How We Use Your Information</h3>
          <p>We use the information we collect for various purposes, including providing and improving our services,
              communicating with you, and sending newsletters or promotional materials.</p>
      </section>

      <section>
          <h3>4. Cookies</h3>
          <p>We use cookies to enhance your experience on our website. You can set your browser to refuse all or some
              browser cookies, but this may affect your experience with our website.</p>
      </section>

      <section>
          <h3>5. Third-Party Links</h3>
          <p>Our website may contain links to third-party websites. We have no control over the content and privacy
              practices of these sites and encourage you to review their privacy policies.</p>
      </section>

      <section>
          <h3>6. Security</h3>
          <p>We implement reasonable security measures to protect your personal information; however, no method of
              transmission over the internet or electronic storage is 100% secure.</p>
      </section>

      <section>
          <h3>7. Changes to This Privacy Policy</h3>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
              Privacy Policy on this page.</p>
      </section>

      <footer>
          <p>This Privacy Policy was last updated on 25<sup>th</sup>-11-2023</p>
      </footer>
        </div>
      </section>

    </>
  );
}

export default PrivacyPolicy;
