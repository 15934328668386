import Moment from 'react-moment';

const ArticleFAQs = () => {
    return (
        <section className="py-4 py-lg-5">
            <div className="container py-4">
                <div className="accordion" id="accordionFAQs">
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsOne" aria-expanded="true" aria-controls="collapseFAQsOne">
                            <span>
                                <span className="site-accordion-title">You dedicate so much time to HTML and CSS. Why?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsTwo" aria-expanded="true" aria-controls="collapseFAQsTwo">
                            <span>
                                <span className="site-accordion-title">Are your courses competitive?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsThree" aria-expanded="true" aria-controls="collapseFAQsThree">
                            <span>
                                <span className="site-accordion-title">Do you guarantee employment after the course</span>
                            </span>
                        </button>
                        <div id="collapseFAQsThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsFour" aria-expanded="true" aria-controls="collapseFAQsFour">
                            <span>
                                <span className="site-accordion-title">Why don’t you teach Angular?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
  export default ArticleFAQs;
  