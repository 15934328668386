import { useEffect, useState } from 'react';


const URL = 'https://backend-api.techsyiq.com/blog/fetch_single_author/';


const Author = (id) => {
    const [author, setAuthor] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    console.log(id)
    console.log(typeof(id))
    const blogAuthor  = () => { fetch(URL+id.id) 
      .then(response => {
        return response.json() 
      }) 
      .then(data => {
        setAuthor(data)
        console.log(data)
      })}

  useEffect(() => {
    setTimeout(() => {
      blogAuthor();
      setIsLoading(false);
    }, 50);
  }, []);



  return (
    <>
{isLoading ? (
        <p>Loading...</p>
      ) : (
        <div class="row mt-4 align-items-center">
            <div class="col-3 col-md-2 col-lg-1 pe-3">
                <img src={"https://res.cloudinary.com/dyfyhudy0/" + author.image } width="100%" className='author-prof-img' alt={ author.firstName } />
            </div>
            <div class="col-9 col-md-10 col-lg-11 ps-0">
                <h6 class="mb-1 font-noto">{ author.firstName } { author.lastName }</h6>
                <p class="text-muted small mb-0">{ author.title }</p>
            </div>
        </div>
      )}
    </>
  );
}

export default Author;
