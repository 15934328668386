const TermsAndConditions = () => {
  return (
    <>
      <section class="py-4 py-lg-5">
        <div class="container">
        <h1>Terms and Conditions</h1>

          <section>
            <h3>1. Acceptance of Terms</h3>
            <p>Welcome to TECHSYIQ. By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions.</p>
          </section>

          <section>
            <h3>2. Use of Services</h3>
            <p>Our website provides information and services related to coding and programming. You agree to use these services for lawful purposes and in a manner consistent with applicable regulations and laws.</p>
          </section>

          <section>
            <h3>3. User Conduct</h3>
            <p>When using our website, you agree not to engage in any activity that could disrupt or interfere with the proper functioning of the site. This includes, but is not limited to, unauthorized access to our systems and data.</p>
          </section>

          <section>
            <h3>4. Intellectual Property</h3>
            <p>All content on this website, including text, graphics, logos, and images, is the property of TECHSYIQ and is protected by intellectual property laws. Unauthorized use of this content is prohibited.</p>
          </section>

          <section>
            <h3>5. Limitation of Liability</h3>
            <p>We strive to provide accurate and up-to-date information, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website.</p>
          </section>

          <section>
            <h3>6. Governing Law</h3>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of KENYA. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of KENYA.</p>
          </section>

          <section>
            <h3>7. Changes to Terms and Conditions</h3>
            <p>We reserve the right to update or change these Terms and Conditions at any time. Your continued use of the website after any changes will constitute your acceptance of such changes.</p>
          </section>

          <footer>
            <p>These Terms and Conditions were last updated on 25<sup>th</sup>-11-2023</p>
          </footer>
        </div>
      </section>

    </>
  );
}

export default TermsAndConditions;
