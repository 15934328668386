import { Link } from "react-router-dom";

const HomeCourseList = ({ course }) => {
    return (
        <div className="col-md-4 my-3">
            <div className="site-card">
                <a href={ '/course/' + course.id } className="text-theme">
                    <h3 className="fancy-card-title text-theme lc-1">
                        { course.name }
                    </h3>
                </a>
                <p className="lc-4">{ course.description }</p>
                <a href={ '/course/' + course.id }>
                    <div className="row">
                        <div className="col-8">
                            <small className="text-theme">{ course.period }</small>
                        </div>
                        <div className="col-4 text-end">
                        
                            <span className="small text-theme">
                                <i className="bi bi-arrow-right"></i>
                            </span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
  }
  
  export default HomeCourseList;
  