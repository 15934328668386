import Testimonials from '../Components/Testimonials';
import CourseTutors from '../Components/CourseTutors';
import LearningProcess from '../Components/LearningProcess';

const BackEndDev = () => {
  return (
    <main class="theme-orange">
        <section className="course-hero-section py-4 py-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-7 my-3">
                        <p className="text-muted">Advanced Level | Remote</p>
                        <h1 className="text-theme">Back-End Dev</h1>

                        <ul className="course-ul my-4">
                            <li>Live Interactive Learning</li>
                            <li>Hands-On Project-based Learning</li>
                            <li>Lifetime Access to Techsyiq Community</li>
                            <li>Up to 15 students per class</li>
                            <li>25 weeks</li>
                            <li>Dedicated Program Support</li>
                        </ul>

                        <a href="/apply-now" className="btn btn-theme">Apply Now</a>
                    </div>
                    <div className="col-md-6 col-lg-5 my-3 ps-lg-5">
                        <img src="./img/course-hero.svg" width="100%" alt="" />
                        <span className="vector-1"></span>
                        <span className="vector-2"></span>
                        <span className="vector-3"></span>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-theme py-4 py-lg-5">
            <div className="container">
                <p className="mb-0">Transition to the next phase of your development journey as you build upon the foundational skills acquired in front-end development. 
                Our immersive course adopts a project-centric methodology, enabling you to seamlessly delve into more advanced realms such as Python, database integration, 
                the Python Django framework, API development, and AI integration.
                Through hands-on projects, you will not only strengthen your understanding of front-end frameworks but also extend your capabilities to backend technologies. 
                This comprehensive approach empowers you to create a fully functional and dynamic web application, integrating both frontend and backend components seamlessly.  </p>
            </div>
        </section>
        <section className="py-4 py-lg-5">
            <div className="container">
                <h2 className="mb-4">Who is this course for?</h2>
                <div className="row">
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/switch-careers.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You are starting or switching a career</h3>
                            <p>You'd like to enter the tech world, and backend or full stack development suits your needs the best. Or you'd like to switch to development from another tech profession to understand where to start and get some relevant knowledge.</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/dead-end.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You've run into a dead-end with self-education</h3>
                            <p>You may have tried web layout, and you're following trends in development. But you'd like to gather your knowledge, advance your skills, and get feedback from industry experts.</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/pm.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You're a PM, a BA, or a product owner</h3>
                            <p>You're already working in IT and aiming to understand developers better. Furthermore, you want to speak your colleagues' language, gain technical knowledge to back up your decisions, and comprehend the code.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="pb-4 pb-lg-5">
            <div className="container">
                <h2 className="mb-4">What job descriptions will fit you?</h2>

                <div className="site-card bg-theme job-description">
                    <div className="row">
                        <div className="col-2 col-lg-1">
                            <div className="job-profile">
                                <img src="./img/apple.png" alt="" />
                            </div>
                        </div>
                        <div className="col-10 col-lg-11">
                            <small>Role:</small>
                            <h3>Junior Backend/Full Stack Developer</h3>
                        </div>
                    </div>
                    <hr className="bg-white" />
                    <h3 className="font-noto">Skills</h3>
                    <ul>
                        <div className="row gx-lg-5">
                            <div className="col-lg-4">
                                <li>Proficient in creating semantic and responsive web pages</li>
                                <li>Understanding of CSS frameworks like Bootstrap or Tailwind CSS</li>
                                <li>Strong understanding of core JavaScript concepts</li>
                                <li>Familiarity with ES6+ features.</li>
                                <li>Experience with asynchronous programming and AJAX.</li>
                                <li>Knowledge of at least one modern frontend framework (e.g., React, Angular, or Vue.js)</li>
                            </div>
                            <div className="col-lg-4">
                                <li>Understanding Git and GitHub for version control</li>
                                <li>Experience with Figma </li>
                                <li>Ability to create mobile-friendly and responsive user interfaces.</li>
                                <li>Knowledge of the logic of client-server architecture when building applications</li>
                                <li>Knowledge of web servers (e.g., Nginx, Apache) and deployment processes.</li>
                            </div>
                            <div className="col-lg-4">
                                <li>Proficient in a backend language like Node.js, Python, Ruby, or Java</li>
                                <li>Understanding of relational databases (e.g., MySQL, PostgreSQL) and NoSQL databases (e.g., MongoDB).</li>
                                <li>Knowledge of a backend framework like Express.js (Node.js), Django (Python), Flask (Python), or Spring Boot (Java).</li>
                                <li>Experience in designing and consuming RESTful APIs. </li>
                                <li>Understanding of GraphQL is a plus.</li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </section>
        <section className="py-4 theme-accordion py-lg-5">
            <div className="container">
                <h2 className="mb-4 text-center">Course Outline</h2>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span>
                            <span className="site-accordion-title">Python Programming</span>
                            <span className="text-theme small accordion-small">2 Weeks <span className="mx-2">&middot;</span> 15 topics</span>
                        </span>
                    </button>
                    <div id="collapseOne" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        
                        This section will take you from the basics to advanced concepts in Python programming. 
                        Starting with fundamental elements like variables and data types, you'll progress to more complex topics such as functions and loops. 
                        The goal is to provide a step-by-step understanding of Python, enabling you to confidently tackle diverse programming challenges.

                        <li>Getting started with Python.</li>
                        <li>Programming Paradigms.</li>
                        <li>Writing Test cases In Python.</li>
                        <li>Modules, Packages, Libraries and Tools.</li>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span>
                            <span className="site-accordion-title">Databases For Backend</span>
                            <span className="text-theme small accordion-small">1 Week <span className="mx-2">&middot;</span> 12 topics</span>
                        </span>
                    </button>
                    <div id="collapseTwo" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        In this part, you'll find out about different databases that work with the backend. 
                        You'll learn how to use SQL statements and understand CRUD, which is all about creating, reading, updating, and deleting data. 
                        Plus, you'll get the hang of designing databases for your applications. This will help you handle data on the backend, making sure your 
                        applications work smoothly.

                        <li>Introduction to Databases.</li>
                        <li>CRUD application.</li>
                        <li>SQL Operators, Sorting and Filtering Data.</li>
                        <li>Database Design.</li>
                        <li>Create a Python application with database.</li>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span>
                            <span className="site-accordion-title">Django FrameWork</span>
                            <span className="text-theme small accordion-small">6 Weeks <span className="mx-2">&middot;</span> 29 topics</span>
                        </span>
                    </button>
                    <div id="collapseThree" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        In this section, you'll get the key skills on Django Framework—a tool that helps you build web applications easily. 
                        You'll learn how to create dynamic websites by using Django's features like models, views, and templates. 
                        The focus is on making your life simpler when developing, so you can build cool stuff without getting worried by complex details.

                        <li>Introduction To Django</li>
                        <li>Views</li>
                        <li>Models</li>
                        <li>Templates</li>
                        <li>Django Project</li>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span>
                            <span className="site-accordion-title">API development & AI Integration</span>
                            <span className="text-theme small accordion-small">4 Weeks <span className="mx-2">&middot;</span> 15 topics</span>
                        </span>
                    </button>
                    <div id="collapseFour" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    In this part, you'll discover how to create and use APIs, which are like messengers that help different parts of websites or applications talk to each other. 
                    You'll also dive into making your sites smarter by integrating AI, so they provide a better experience. 
                    It's all about adding cool features and making your sites work seamlessly. 
                    Are you excited to make your projects more powerful and user-friendly with API development and AI integration?

                        <li>REST APIs</li>
                        <li>Django REST Framework DRF</li>
                        <li>Advanced API development</li>
                        <li>Working with Any API</li>
                        <li>API and Django Project</li>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <span>
                            <span className="site-accordion-title">Backend Projects</span>
                            <span className="text-theme small accordion-small">4 Weeks <span className="mx-2">&middot;</span> 11 Projects</span>
                        </span>
                    </button>
                    <div id="collapseFive" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    
                        Once you finish the lessons, you'll get to practice what you learned by doing projects. 
                        First, you'll do <strong>8 smaller projects</strong> to get really good at the basics. 
                        These projects work and help you practice what you've learned. 
                        After that, there are 3 bigger projects where you use more skills together. 
                        Finally, there's one last big project where you use everything you learned to make something impressive. 
                        It's like putting all the pieces together to make something cool. 
                        This way, you not only understand how things work but also get really good at using your skills to make awesome websites.
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <CourseTutors />
        <LearningProcess />
        <section className="pb-4 pb-lg-5">
            <div className="container">
            <h2 className="fancy-heading">Why Us?</h2>
            <div className="row mt-4 gx-lg-5">
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/FlippedClassrooms.svg" alt="Flipped classrooms" />
                        <h3>Flipped classrooms</h3>
                        <p>Study theory at home and focus on practice in live classNamees, where you’ll be guided by one of our expert tutors.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/LiveClasses.svg" alt="Live classes" />
                        <h3>Live classes</h3>
                        <p>Join our Zoom classes led by industry experts — up to 15 students per className, during stand-ups.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Support.svg" alt="Support" />
                        <h3>Support</h3>
                        <p>Get the help you need to break into your new career. We provide our students with meaningful support and career guidance.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/SoftSkills.svg" alt="Soft Skills" />
                        <h3>Soft Skills</h3>
                        <p>Go beyond the technical stuff. Our approach emulates a real tech working environment, where our students work in groups, guided by a team lead.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Employability.svg" alt="Employability" />
                        <h3>Employability</h3>
                        <p>Landing a job in tech can be tough — but we’ll provide you with the tools and knowledge necessary to nail your first interview.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Community.svg" alt="Community" />
                        <h3>Community</h3>
                        <p>Get access to the Techsyiq Community that comprises our alumni, a vibrant, innovative development company, and a growing network of partners.</p>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <section className="testimonials testimonial-theme py-4 py-lg-5">
            <Testimonials />
        </section>

        <section className="py-4 py-lg-5 bg-pink-1">
            <div className="container">
                <h2>Tuition fees</h2>
                <p className="mb-4">Switching careers can be challenging, and we understand. We’re here to provide you with the flexibility you need.</p>
                <div className="row gx-lg-5">
                    <div className="col-md-4 my-3">
                        <div className="site-card theme-card tuition-card h-100">
                            <p className="text-muted mb-2">Full Tuition</p>
                            <h3 className="font-noto text-theme fs-3">Ksh. 50,000</h3>
                            <p className="small mb-0 text-muted">One-time full payment</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="site-card theme-card tuition-card h-100">
                            <p className="text-muted mb-2">Installments plan</p>
                            <h3 className="font-noto text-theme fs-3">Ksh. 18,000</h3>
                            <p className="small mb-0 text-muted">3 monthly payments</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="site-card theme-card tuition-card h-100">
                            <p className="text-muted mb-2">Talk to us</p>
                            <a href="/book-call" className="btn btn-outline-theme mt-4">Book a Call</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  );
}

export default BackEndDev;
