import Axios from "axios";
import { useState } from "react";

const BookCall = () => {

    const API_URL = 'https://backend-api.techsyiq.com/contact_us/book_call';

    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        date: '',
        time: '',
        call_type: ''
    })

    function submit(e){
        e.preventDefault();
        Axios.post(API_URL,{
            name: data.name,
            email: data.email,
            phone: data.phone,
            date: data.date,
            time: data.time,
            call_type: data.call_type
        }).then(res=>{
            document.getElementById('thankYou').classList.remove('d-none');
            document.getElementById('contactForm').reset()
        })
    }

    function dismissThankYou(){
        document.getElementById('thankYou').classList.add('d-none');
    }

    function handle(e){
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        console.log(newData)
    }

  return (
    <>
    <section id="thankYou" className="d-none form-submit-success" onClick={dismissThankYou}>
        <div className="thank-you-inner">
            <h3>Call Booked Successfully!</h3>
            <p>You've been scheduled for a callback! We will get in touch as soon as possible!</p>
            <button onClick={dismissThankYou} className="btn btn-main btn-sm">OK</button>
        </div>
    </section>
    <section class="py-4 py-lg-5 why-us-section">
        <div class="container">
            <h1 className="text-center mb-4">Book a Call</h1>
            <div className="row">
                <div className="col-lg-8 mx-lg-auto">
                    <form onSubmit={(e)=> submit(e)} className="site-card pt-3" id="contactForm">

                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <label for="name" className="small">Your Name</label>
                                <input type="text" onChange={(e)=>handle(e)} name="name" className="form-control" id="name" />
                            </div>
                            <div className="col-md-6 mb-1">
                                <label for="email" className="small">Email Address</label>
                                <input type="email" onChange={(e)=>handle(e)} name="email" className="form-control" id="email" />
                            </div>
                            <div className="col-md-6 my-1">
                                <label for="phone" className="small">Phone</label>
                                <input type="tel" onChange={(e)=>handle(e)} name="phone" className="form-control" id="phone" />
                            </div>
                            <div className="col-md-3 my-1">
                                <label for="date" className="small">Date</label>
                                <input type="date" onChange={(e)=>handle(e)} name="date" className="form-control" id="date" />
                            </div>
                            <div className="col-md-3 my-1">
                                <label for="time" className="small">Time</label>
                                <input type="time" onChange={(e)=>handle(e)} className="form-control" name="time" id="time" />
                            </div>
                            <div className="col-md-12 my-1">
                                <label for="call_type" className="small">Call Type</label>
                                <select class="form-select" onChange={(e)=>handle(e)} id="call_type" name="call_type">
                                    <option selected disabled>Call Type</option>
                                    <option value="general">General</option>
                                    <option value="tech mod">Tech Mod</option>
                                    <option value="front-end">Frontend</option>
                                    <option value="backend">Backend</option>
                                </select>
                            </div>
                            <div className="col-md-12 mt-3">
                                <button type="submit" className="btn btn-main">Book Call</button>
                            </div>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </section>

    </>
  );
}

export default BookCall;
