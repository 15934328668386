import { useEffect, useState } from 'react';
import Moment from 'react-moment';

const API_URL = 'https://backend-api.techsyiq.com/blog/get_category';

const URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';

const BlogTabsContent = () => {
    const [categories, setCategories] = useState([])

    const [blogs, setBlogs] = useState([])
  
  const blogCategories = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setCategories(data)
  }

  const blogArticles = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    
    console.log(data)
    setBlogs(data)
  }


  useEffect(() => {
    blogArticles();
    blogCategories();
  }, []);



  return (
    <>         
    {
        categories.length > 0
        ? (
        <>
            {categories.map((cat) => 
            
              <div className="tab-pane fade" id={ 'pills-' + cat.id } role="tabpanel">
                    {
                      <div>
                          {blogs.map((blog) =>
                            <div className={cat.name === blog.category_name ? "article-list " : " d-none"}>
                              <div className="row">
                                  <div className="col-md-3">
                                      <div className="blog-img-box">
                                          <img src={"https://res.cloudinary.com/dyfyhudy0/" + blog.image } width="100%" alt={ blog.title } />
                                      </div>
                                  </div>
                                  <div className="col-md-9">
                                      <p className="small text-muted mb-1 fw-400">
                                      <span className="text-blue">{ blog.category_name }</span>
                                      <span className="mx-2">|</span>
                                      <Moment format="YYYY/MM/DD">{ blog.date_published }</Moment></p>
                                      <h2 className="font-noto">
                                          <a href={ '/blog/' + blog.id } className='text-dark'>{blog.title}</a>
                                      </h2>
                                      <p className="lc-4">{ blog.description }</p>
                                  </div>
                              </div>
                          </div>
                          )}
                      </div>
                    }
              </div>
            )}
        </>
        )
        :
        <div>
        </div>
    }

    </>
  );
}

export default BlogTabsContent;
