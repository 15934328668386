
const Footer = () => {
  return (
    <>
    <footer className="py-4 py-lg-5">
      <div className="container">
          <div className="container">
              <div className="row">
                  <div className="col-6 my-3">
                      <svg width="142" height="27" viewBox="0 0 142 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_143_360)">
                      <path d="M14.9834 11.2816V15.0474C15.0042 16.0136 14.8259 16.9742 14.4587 17.8728C14.0916 18.7714 13.5431 19.5899 12.8454 20.2803C12.1476 20.9708 11.3147 21.5194 10.3954 21.8939C9.47613 22.2684 8.48896 22.4613 7.49176 22.4613C6.49456 22.4613 5.50739 22.2684 4.5881 21.8939C3.66881 21.5194 2.83589 20.9708 2.13816 20.2803C1.44044 19.5899 0.891935 18.7714 0.524799 17.8728C0.157663 16.9742 -0.0207154 16.0136 0.000108227 15.0474V9.26416H3.65362V15.0537C3.63863 15.5513 3.72683 16.0468 3.91297 16.5108C4.09911 16.9748 4.37943 17.3979 4.73731 17.755C5.0952 18.1122 5.52338 18.3961 5.9965 18.5899C6.46963 18.7838 6.97809 18.8837 7.49176 18.8837C8.00544 18.8837 8.51389 18.7838 8.98702 18.5899C9.46015 18.3961 9.88832 18.1122 10.2462 17.755C10.6041 17.3979 10.8844 16.9748 11.0706 16.5108C11.2567 16.0468 11.3449 15.5513 11.3299 15.0537V11.2816H14.9834Z" fill="#4D4E4E"/>
                      <path d="M16.8035 5.72607H0V9.26695H16.8035V5.72607Z" fill="#4D4E4E"/>
                      <path d="M1.82676 3.54088C2.83564 3.54088 3.65351 2.74823 3.65351 1.77044C3.65351 0.792653 2.83564 0 1.82676 0C0.817866 0 0 0.792653 0 1.77044C0 2.74823 0.817866 3.54088 1.82676 3.54088Z" fill="#C83898"/>
                      <path d="M7.49002 3.54088C8.49891 3.54088 9.31678 2.74823 9.31678 1.77044C9.31678 0.792653 8.49891 0 7.49002 0C6.48114 0 5.66327 0.792653 5.66327 1.77044C5.66327 2.74823 6.48114 3.54088 7.49002 3.54088Z" fill="#F79F28"/>
                      <path d="M13.1566 3.54088C14.1655 3.54088 14.9833 2.74823 14.9833 1.77044C14.9833 0.792653 14.1655 0 13.1566 0C12.1477 0 11.3298 0.792653 11.3298 1.77044C11.3298 2.74823 12.1477 3.54088 13.1566 3.54088Z" fill="#3778B8"/>
                      <path d="M29.5385 8.65916H24.3622V5.75488H38.0579V8.65916H32.8522V21.4545H29.5385V8.65916Z" fill="#3778B8"/>
                      <path d="M39.2606 5.75488H50.1557V8.65916H42.5709V11.8453H48.1427V14.5817H42.5709V18.566H50.4498V21.4671H39.2606V5.75488Z" fill="#3778B8"/>
                      <path d="M59.6816 5.46955C61.1246 5.43966 62.5493 5.78711 63.8058 6.47541C65.0623 7.16371 66.1042 8.16742 66.822 9.38098L64.3188 11.0944C63.1663 9.31869 61.6206 8.43084 59.6816 8.43084C56.6621 8.43084 54.453 10.6193 54.453 13.6155C54.453 16.6116 56.6621 18.7558 59.6816 18.7558C61.6184 18.7558 63.1641 17.8753 64.3188 16.1144L66.822 17.8278C65.4234 20.276 62.7731 21.7265 59.6555 21.7265C54.9203 21.8247 50.9433 18.0875 51.0413 13.5933C50.9662 9.1086 54.9661 5.37454 59.6816 5.46955Z" fill="#3778B8"/>
                      <path d="M68.6618 5.75488H71.982V11.7725H79.1518V5.75488H82.4654V21.4545H79.1452V14.6768H71.982V21.4545H68.6618V5.75488Z" fill="#3778B8"/>
                      <path d="M86.3346 16.6023C87.3902 18.0053 88.5437 18.8858 90.3117 18.8858C91.7594 18.8858 92.6188 18.2207 92.6188 17.1502C92.6188 16.2222 92.1025 15.8833 90.5568 15.1992L88.815 14.4391C86.3346 13.3686 84.8935 12.0131 84.8935 9.91958C84.8935 7.23066 87.2006 5.46973 90.2692 5.46973C92.7495 5.46973 94.6122 6.63522 95.6187 8.44367L93.3606 10.1318C92.5273 8.91875 91.4979 8.32332 90.2692 8.32332C89.1156 8.32332 88.2562 8.87123 88.2562 9.84672C88.2562 10.6797 88.7725 11.0597 90.145 11.6552L91.9881 12.4406C94.7135 13.6061 95.988 14.9142 95.988 17.0298C95.988 19.9088 93.5567 21.7394 90.3182 21.7394C89.0482 21.7578 87.7967 21.4439 86.6953 20.8307C85.594 20.2176 84.6836 19.328 84.0602 18.2555L86.3346 16.6023Z" fill="#3778B8"/>
                      <path d="M105.014 15.2722V21.4545H101.7V15.2944L96.1057 5.75488H99.7853L103.38 11.9878L106.939 5.75488H110.596L105.014 15.2722Z" fill="#3778B8"/>
                      <path d="M111.886 5.75488H115.2V21.4545H111.886V5.75488Z" fill="#3778B8"/>
                      <path d="M125.576 5.75788C120.912 5.65653 116.912 9.39377 116.984 13.8911C116.912 18.3853 120.906 22.1257 125.576 22.0276C126.298 22.0263 127.018 21.9412 127.719 21.7742C127.166 20.8989 126.703 19.9728 126.337 19.0093C126.092 19.0412 125.845 19.057 125.598 19.0568C122.608 19.0568 120.396 16.9126 120.396 13.8911C120.396 10.8697 122.608 8.72867 125.598 8.72867C128.589 8.72867 130.801 10.914 130.801 13.8911C130.815 14.8558 130.561 15.8064 130.066 16.6434C130.108 16.7986 130.16 16.9601 130.219 17.1311C130.255 17.2293 130.291 17.3338 130.337 17.4478C130.647 18.2745 131.062 19.0606 131.572 19.7884C132.411 19.0369 133.078 18.1245 133.533 17.1091C133.988 16.0938 134.219 14.9979 134.213 13.8911C134.294 9.3811 130.249 5.65653 125.576 5.75788Z" fill="#3778B8"/>
                      <path d="M129.651 17.3211C129.48 16.8493 129.371 16.3589 129.324 15.8611C129.254 14.7141 129.38 13.5638 129.696 12.4564L126.86 11.2275C126.86 11.2275 126.442 12.9188 126.412 13.0771C126.305 13.695 126.244 14.3195 126.229 14.9458C126.199 16.1609 126.396 17.3712 126.811 18.5183C127.641 20.7828 128.843 22.7655 130.804 24.2667L130.834 24.2889C131.01 24.4219 131.19 24.5486 131.37 24.6753C133.62 26.2332 136.326 27.0513 139.092 27.0095C140.068 27.0231 141.042 26.9167 141.99 26.6927V24.159C140.81 24.2319 140.052 24.2541 139.735 24.2541C137.716 24.2541 135.69 23.8676 133.954 22.832C132.001 21.6005 130.524 19.7744 129.758 17.641C129.729 17.5428 129.69 17.432 129.651 17.3211Z" fill="#F79F28"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_143_360">
                      <rect width="142" height="27" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>
  
                      <ul>
                          <li>Techsyiq Bootcamp</li>
                          <li>Nairobi, Kenya</li>
                      </ul>
                  </div>
                  <div className="col-6 my-3 text-end">
                      <a href="#top" className="btn toTop">Back to Top <i className="ms-2 bi bi-arrow-up"></i></a>
  
                      <ul>
                          <li><a href="">+254 727 052 252</a></li>
                          <li><a href="">info@techsyiq.com</a></li>
                      </ul>
                  </div>
              </div>
              <div className="row">
                  <div className="col-6 order-3 my-2 order-md-0 col-md-3">
                      <small>©Techsyiq Bootcamp 2024</small>
                  </div>
                  <div className="col-6 order-1 my-2 order-md-0 col-md-3">
                      <small><a href="/privacy-policy">Privacy Policy</a></small>
                  </div>
                  <div className="col-6 order-2 my-2 order-md-0 col-md-3">
                      <small><a href="/terms-and-conditions">Terms and Conditions</a></small>
                  </div>
                  <div className="col-6 order-4 my-2 order-md-0 col-md-3 text-end">
                      <a href="" className="ms-4"><i className="bi bi-twitter"></i></a>
                      <a href="" className="ms-4"><i className="bi bi-instagram"></i></a>
                      <a href="" className="ms-4"><i className="bi bi-linkedin"></i></a>
                  </div>
              </div>
          </div>
      </div>
    </footer>

    <div className="modal fade" id="applyModal" tabindex="-1" aria-labelledby="applyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="applyModalLabel">Choose Date and Time</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label className='text-muted small'>Date</label>
                                <input type="date" className='form-control' />
                            </div>
                            <div className='col-md-6'>
                                <label className='text-muted small'>Time</label>
                                <input type="time" className='form-control' />
                            </div>
                            <div className='col-md-12'>
                                <label className='text-muted small'>Module</label>
                                <select className='form-select'>
                                    <option>Techie Mod</option>
                                    <option>Front End Dev</option>
                                    <option>Back End Dev</option>
                                </select>
                            </div>
                            <div className='col-md-12 mt-4'>
                                <button type='submit' className='btn btn-block btn-main'>Book Call</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</>
  );
}

export default Footer;
