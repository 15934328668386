import { useEffect, useState } from "react";

const Stats = () => {

    const API_URL = 'https://backend-api.techsyiq.com/marketing/fetch_analysis_details';

    const [statsList, setStats] = useState([])
  
    const stats = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setStats(data)
  }

  useEffect(() => {
    stats();
  }, []);

  return (
    <>
    {statsList.map((stats) => 
      <section class="py-4 py-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-md-4 my-3">
                    <div class="stat-card">
                        <h3>{ stats.employed_alumin }+</h3>
                        <p class="small text-muted">Employed Alumni</p>
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="stat-card">
                        <h3>{ stats.graduates }+</h3>
                        <p class="small text-muted">Cohorts Graduated</p>
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="stat-card">
                        <h3>{ stats.commitment_percentage }%</h3>
                        <p class="small text-muted">Our Commitment</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
      )}
    </>
  );
}

export default Stats;
