import Testimonials from '../Components/Testimonials';
import CourseTutors from '../Components/CourseTutors';
import LearningProcess from '../Components/LearningProcess';

const TechieMod = () => {
  return (
    <main class="theme-pink">
        <section className="course-hero-section py-4 py-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-7 my-3">
                        <p className="text-muted">Beginner Level | Remote</p>
                        <h1 className="text-theme">Techie Mod</h1>

                        <ul className="course-ul my-4">
                            <li>Live class</li>
                            <li>Up to 15 students per class</li>
                            <li>9 weeks</li>
                            <li>Course materials and classes are in English</li>
                            <li>One on One coding support </li>
                            <li>Project based learning</li>
                        </ul>

                        <a href="/apply-now" className="btn btn-theme">Apply Now</a>
                    </div>
                    <div className="col-md-6 col-lg-5 my-3 ps-lg-5">
                        <img src="./img/course-hero.svg" width="100%" alt="" />
                        <span className="vector-1"></span>
                        <span className="vector-2"></span>
                        <span className="vector-3"></span>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-theme py-4 py-lg-5">
            <div className="container">
                <p className="mb-0">Take your first steps in front-end development. Our course has a project-based approach, so you will create your own project from scratch during the HTML/CSS, JavaScript, and CSS framework modules. Gain knowledge to master the profession independently and learn to speak the tech community's and developers' language.</p>
            </div>
        </section>
        <section className="py-4 py-lg-5">
            <div className="container">
                <h2 className="mb-4">Who is this course for?</h2>
                <div className="row">
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/switch-careers.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You are starting or switching a career</h3>
                            <p>You'd like to enter the tech world, and front-end development suits your needs the best. Or you'd like to switch to development from another tech profession to understand where to start and get some relevant knowledge.</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/dead-end.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You've run into a dead-end with self-education</h3>
                            <p>You may have tried web layout, and you're following trends in development. But you'd like to gather your knowledge, advance your skills, and get feedback from industry experts.</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/pm.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You're a PM, a BA, or a product owner</h3>
                            <p>You're already working in IT and aiming to understand developers better. Furthermore, you want to speak your colleagues' language, gain technical knowledge to back up your decisions, and comprehend the code.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="pb-4 pb-lg-5">
            <div className="container">
                <h2 className="mb-4">What job descriptions will fit you?</h2>

                <div className="site-card bg-theme job-description">
                    <div className="row">
                        <div className="col-2 col-lg-1">
                            <div className="job-profile">
                                <img src="./img/apple.png" alt="" />
                            </div>
                        </div>
                        <div className="col-10 col-lg-11">
                            <small>Role:</small>
                            <h3>Junior front-end developer</h3>
                        </div>
                    </div>
                    <hr className="bg-white" />
                    <h3 className="font-noto">Skills</h3>
                    <ul>
                        <div className="row gx-lg-5">
                            <div className="col-lg-4">
                                <li>Knowledge of HTML and skills of semantic markup</li>
                                <li>Experience in creating CSS animations, transformations and using other styles</li>
                                <li>Skills in using pre- and post-processors </li>
                                <li>Skills of working with basic JavaScript tools </li>
                                <li>Experience working with web servers</li>
                            </div>
                            <div className="col-lg-4">
                                <li>Experience with Git and GitHub </li>
                                <li>Understanding of BEM methodology </li>
                                <li>Experience with Figma </li>
                                <li>Ability to work with console and DevTools, check the code for errors, perform debugging </li>
                                <li>Knowledge of the logic of client-server architecture when building applications</li>
                            </div>
                            <div className="col-lg-4">
                                <li>Skills in applying CSS-styles and visual effects to the layout </li>
                                <li>Experience creating responsive websites </li>
                                <li>Experience using JavaScript (primitives, objects, arrays, functions)</li>
                                <li>Experience with manipulating the DOM tree </li>
                                
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </section>
        <section className="py-4 theme-accordion py-lg-5">
            <div className="container">
                <h2 className="mb-4 text-center">Course Outline</h2>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span>
                            <span className="site-accordion-title">HTML & CSS</span>
                            <span className="text-theme small accordion-small">15 days <span className="mx-2">&middot;</span> 35 topics</span>
                        </span>
                    </button>
                    <div id="collapseOne" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    In this section, you will acquire the essential skills of crafting responsive websites through the comprehensive study of HTML and advanced CSS. 
                    This journey involves delving into the intricacies of these technologies, enabling you to design and structure web content seamlessly. 
                    From understanding the fundamentals of HTML, which forms the backbone of web pages, to mastering advanced CSS techniques for stylish layouts and 
                    responsiveness, you will gain the expertise to create compelling and adaptable online experiences. Embrace the empowerment that comes with building 
                    websites that not only meet but exceed modern standards, providing optimal user experiences across diverse devices and screen sizes.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span>
                            <span className="site-accordion-title">JavaScript</span>
                            <span className="text-theme small accordion-small">15 days <span className="mx-2">&middot;</span> 50 topics</span>
                        </span>
                    </button>
                    <div id="collapseThree" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    Embarking on your journey into web development, you will delve into the dynamic realm of JavaScript to augment your skills in crafting interactive and dynamic websites. 
                    Through comprehensive learning, you'll gain mastery over JavaScript's capabilities, from manipulating the Document Object Model (DOM) to handling user interactions
                    and creating seamless, engaging web experiences. This journey involves understanding the fundamentals of JavaScript, enabling you to enhance the functionality of your 
                    websites and bring them to life with user-friendly features. Explore the power of asynchronous programming, event-driven architecture, and the vast ecosystem of JavaScript libraries and frameworks. 
                    By incorporating JavaScript into your skill set, you'll be well-equipped to create web applications that captivate and respond to user actions, providing a dynamic and enriched online environment.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span>
                            <span className="site-accordion-title">Bootstrap &  Tailwind</span>
                            <span className="text-theme small accordion-small">15 days <span className="mx-2">&middot;</span> 18 topics</span>
                        </span>
                    </button>
                    <div id="collapseFour" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    In this part of your journey in learning web development, you'll be exploring two front-end frameworks: Bootstrap and Tailwind CSS. 
                    The goal is to improve your ability to create websites that look good and work well on different devices. As you learn, Bootstrap will help you simplify your design work with its ready-made parts and grid system. 
                    Tailwind CSS, on the other hand, allows you to have more control over how things look, letting you create unique designs. These frameworks are important tools that make it easier for you to build modern websites that work on both computers and mobile devices. 
                    Enjoy learning how to create nice-looking and adaptable user interfaces that can fit well on various screens, using the features of both Bootstrap and Tailwind CSS.
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <CourseTutors />
        <LearningProcess />
        <section className="pb-4 pb-lg-5">
            <div className="container">
            <h2 className="fancy-heading">Why Us?</h2>
            <div className="row mt-4 gx-lg-5">
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/FlippedClassrooms.svg" alt="Flipped classrooms" />
                        <h3>Flipped classrooms</h3>
                        <p>Study theory at home and focus on practice in live classrooms, where you’ll be guided by one of our expert tutors.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/LiveClasses.svg" alt="Live classes" />
                        <h3>Live classes</h3>
                        <p>Join our Zoom classes led by industry experts — up to 15 students per className, during stand-ups.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Support.svg" alt="Support" />
                        <h3>Support</h3>
                        <p>Get the help you need to break into your new career. We provide our students with meaningful support and career guidance.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/SoftSkills.svg" alt="Soft Skills" />
                        <h3>Soft Skills</h3>
                        <p>Go beyond the technical stuff. Our approach emulates a real tech working environment, where our students work in groups, guided by a team lead.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Employability.svg" alt="Employability" />
                        <h3>Employability</h3>
                        <p>Landing a job in tech can be tough — but we’ll provide you with the tools and knowledge necessary to nail your first interview.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Community.svg" alt="Community" />
                        <h3>Community</h3>
                        <p>Get access to the Techsyiq Community that comprises our alumni, a vibrant, innovative development company, and a growing network of partners.</p>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <section className="testimonials testimonial-theme py-4 py-lg-5">
            <Testimonials />
        </section>

        <section className="py-4 py-lg-5 bg-pink-1">
            <div className="container">
                <h2>Tuition fees</h2>
                <p className="mb-4">Switching careers can be challenging, and we understand. We’re here to provide you with the flexibility you need.</p>
                <div className="row gx-lg-5">
                    <div className="col-md-4 my-3">
                        <div className="site-card theme-card tuition-card h-100">
                            <p className="text-muted mb-2">Full Tuition</p>
                            <h3 className="font-noto text-theme fs-3">Ksh. 9,000</h3>
                            <p className="small mb-0 text-muted">One-time full payment</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="site-card theme-card tuition-card h-100">
                            <p className="text-muted mb-2">Installments plan</p>
                            <h3 className="font-noto text-theme fs-3">Ksh. 3,500</h3>
                            <p className="small mb-0 text-muted">3 monthly payments</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="site-card theme-card tuition-card h-100">
                            <p className="text-muted mb-2">Talk to us</p>
                            <a href="/book-call" className="btn btn-outline-theme mt-4">Book a Call</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  );
}

export default TechieMod;
