import Moment from 'react-moment';
// import { Link, Route } from 'react-router-dom';

const BlogList = ({ blog }) => {
    return (
      <>
      <div className="article-list">
            <div className="row">
                <div className="col-md-3">
                    <div className="blog-img-box">
                        <img src={"https://res.cloudinary.com/dyfyhudy0/" + blog.image } width="100%" alt={ blog.title } />
                    </div>
                </div>
                <div className="col-md-9">
                    <p className="small text-muted mb-1 fw-400">
                    <span className="text-blue">{ blog.category_name }</span>
                    <span className="mx-2">|</span>
                    <Moment format="YYYY/MM/DD">{ blog.date_published }</Moment></p>
                    <h2 className="font-noto">
                        <a href={ '/blog/' + blog.id } className='text-dark'>{blog.title}</a>
                    </h2>
                    <p className="lc-4">{ blog.description }</p>
                </div>
            </div>
        </div>
      </>
    );
  }
  
  export default BlogList;
  