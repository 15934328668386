import { useEffect, useState } from 'react';

import BlogList from './BlogList';
import { Link } from 'react-router-dom';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';


const OtherArticles = () => {
    const [blogs, setBlogs] = useState([])
  
  const blogArticles = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    setBlogs(data)
  }


  useEffect(() => {
    blogArticles();
  }, []);



  return (
    <>
    <section className="py-4 py-lg-5 bg-light">
        <div className="container">
          <h2 className='text-blue mb-4'>Other articles</h2>
            {
                blogs.length > 0
                ? (
                <div className="row">
                    {blogs.map((blog) => 
                      <div className='col-md-3 my-2 blog-list-page'>
                        <BlogList blog={blog} />
                      </div>
                    )}
                </div>
                )
                :
                <div>
                </div>
            }

        <div class="mt-4 text-center">
          <Link to="/blog" className="btn btn-outline-main">Go to Blog</Link>
        </div>
        </div>
    </section>
    </>
  );
}

export default OtherArticles;
