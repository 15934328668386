import { useEffect, useState } from 'react';

const API_URL = 'https://backend-api.techsyiq.com/blog/get_category';

const BlogTabs = () => {
    const [categories, setCategories] = useState([])
  
  const blogCategories = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setCategories(data)
  }


  useEffect(() => {
    blogCategories();
  }, []);



  return (
    <>         
    {
        categories.length > 0
        ? (
        <>
            {categories.map((category) => 
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id={ '#pills-' + category.id + '-tab'} data-bs-toggle="pill" data-bs-target={ '#pills-' + category.id } type="button" role="tab" aria-controls={ '#pills-' + category.id } aria-selected="false">{ category.name }</button>
                </li>
            )}
        </>
        )
        :
        <div>
        </div>
    }

    </>
  );
}

export default BlogTabs;