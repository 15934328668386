import { useEffect, useState } from 'react';

import Newsletters from "../Components/Newsletters";
import Testimonials from "../Components/Testimonials";




const About = () => {
    const API_URL = 'https://backend-api.techsyiq.com/team/fetch';

  const [teamList, setTeam] = useState([])
  
    const teamMembers = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setTeam(data)
  }


  useEffect(() => {
    teamMembers();
  }, []);

  return (
    <>
      <section className="about-hero-section py-4 py-lg-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <p>
                        At Techsyiq, we firmly believe that everyone should have the opportunity to upskill and stay versed with the latest technologies. 
                        That's why we are committed to providing extensive training to our tech mentees. 
                        In today's rapidly evolving tech landscape, continuous learning is essential, and our training programs are designed to empower individuals, 
                        ensuring they not only keep up with the latest advancements but also thrive in the dynamic world of technology.
                        </p>
                        <p>
                        Our approach at Techsyiq is rooted in the belief that fostering a culture of continuous learning leads to individual and collective success. 
                        Our training initiatives go beyond traditional boundaries, offering a comprehensive platform for mentees to enhance their tech skills.
                        
                        </p>
                    </div>
                    <div className="col-lg-6 ps-lg-5">
                        <img src="./img/about-img.png" width="100%" alt="" />
                        <span className="vector-1"></span>
                        <span className="vector-2"></span>
                        <span className="vector-3"></span>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-4 py-lg-5">
            <div className="container">
                <h2>Meet our team</h2>
                <p className="mb-5">To be the coding bootcamp our students expect us to be, it takes an eclectic, vibrant group of passionate individuals. Meet the people leading the way at Techsyiq.</p>
                
                <div class="team-section">
                    {teamList.map((team) => 
                    <div className="row team-card-row align-items-center my-3">
                        <div className="col-md-3 team-row-img my-3 pe-lg-4">
                            <div className="team-card">
                                <div className="team-img">
                                    <img src={"https://res.cloudinary.com/dyfyhudy0/" + team.image } alt="" />
                                </div>
                                <div className="team-footer">
                                    <div className="row">
                                        <div className="col-10">
                                            <p className="mb-0 fw-500">{ team.name }</p>
                                            <p className="small mb-0 text-muted">{ team.title }</p>
                                        </div>
                                        <div className="col-2 text-end">
                                            <a href={ team.social_media_link } className="text-blue">
                                                <i className="bi bi-twitter"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 team-row-description my-3">
                            <h3 className="text-blue"> { team.name }</h3>
                            <p>{ team.description }</p>
                        </div>
                    </div>
                    )}
                </div>
                

            </div>
        </section>

        <section class="testimonials py-4 py-lg-5">
            <Testimonials />
        </section>

      <Newsletters />

    </>
  );
}

export default About;
