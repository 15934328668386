import Axios from "axios";
import Newsletters from "../Components/Newsletters";
import { useState } from "react";

const Contact = () => {

    const API_URL = 'https://backend-api.techsyiq.com/contact_us/message';

    const [data, setData] = useState({
        name: '',
        subject: '',
        email: '',
        message: ''
    })

    function submit(e){
        e.preventDefault();
        Axios.post(API_URL,{
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message
        }).then(res=>{
            document.getElementById('thankYou').classList.remove('d-none');
            document.getElementById('contactForm').reset()
        })
    }

    function dismissThankYou(){
        document.getElementById('thankYou').classList.add('d-none');
    }

    function handle(e){
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        console.log(newData)
    }

  return (
    <>
    <section id="thankYou" className="d-none form-submit-success" onClick={dismissThankYou}>
        <div className="thank-you-inner">
            <h3>Message Sent Successfully!</h3>
            <p>Your message was sent successfully! We will get in touch as soon as possible!</p>
            <button onClick={dismissThankYou} className="btn btn-main btn-sm">OK</button>
        </div>
    </section>
    <section class="py-4 py-lg-5 why-us-section">
        <div class="container">
            <h1 className="text-center mb-4">Contact Us</h1>
            <div className="row">
                <div className="col-lg-4">
                    <div className="site-card">
                        <div className="contact-info-card">
                            <span className="contact-info-icon">
                                <i className="bi bi-envelope text-blue"></i>
                            </span>
                            <span className="contact-info-content">
                                <h3>Our Email</h3>
                                <a href="mailto:info@techsyiq.com" className="text-blue fw-400">info@techsyiq.com</a>
                            </span>
                        </div>
                    </div>
                
                    <div className="site-card my-4">
                        <div className="contact-info-card">
                            <span className="contact-info-icon">
                                <i className="bi bi-envelope text-blue"></i>
                            </span>
                            <span className="contact-info-content">
                                <h3>Other Email</h3>
                                <a href="mailto:admissions@techsyiq.com" className="text-blue fw-400">admissions@techsyiq.com</a>
                            </span>
                        </div>
                    </div>
                
                    <div className="site-card">
                        <div className="contact-info-card">
                            <span className="contact-info-icon">
                                <i className="bi bi-telephone text-blue"></i>
                            </span>
                            <span className="contact-info-content">
                                <h3>Telephone</h3>
                                <a href="tel:+254740037977" className="text-blue fw-400">+254 740 037 977</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <form onSubmit={(e)=> submit(e)} className="site-card pt-3" id="contactForm">
                        {/* <h2>Send a Message</h2>
                        <p>Leave an enquiry here, and one of us will contact you to respond.</p> */}

                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <label for="name" className="small">Your Name</label>
                                <input type="text" onChange={(e)=>handle(e)} className="form-control" id="name" />
                            </div>
                            <div className="col-md-6 mb-1">
                                <label for="email" className="small">Email Address</label>
                                <input type="email" onChange={(e)=>handle(e)} className="form-control" id="email" />
                            </div>
                            <div className="col-md-12 my-1">
                                <label for="subject" className="small">Subject</label>
                                <input type="text" onChange={(e)=>handle(e)} className="form-control" id="subject" />
                            </div>
                            <div className="col-md-12 my-1">
                                <label for="message" className="small">Your Message</label>
                                <textarea className="form-control" id="message" onChange={(e)=>handle(e)} rows="4"></textarea>
                            </div>
                            <div className="col-md-12 mt-3">
                                <button type="submit" className="btn btn-main">Send Message</button>
                            </div>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </section>
    <Newsletters />

    </>
  );
}

export default Contact;
